
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation, Scrollbar } from "swiper";
import 'swiper/css';
import "swiper/css/scrollbar";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
const RoadMap = ({ t }) => {
    const roadmap = t('home:roadmap', {}, { returnObjects: true });
    return (<section className='roadmap' id={'roadmap'}>
            <div className="container">
                <div className="roadmap_wrapper">
                    <h2 className='roadmap_title'>{roadmap.title}</h2>
                    <div className="roadmap_slider" data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400}>
                        <Swiper keyboard={{
            enabled: true,
            onlyInViewport: true
        }} centeredSlides={false} grabCursor={true} cssMode={true} spaceBetween={20} mousewheel={true} autoplay={true} navigation={{ clickable: true }} breakpoints={{
            375: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            }
        }} lazy={false} modules={[Navigation, Scrollbar, Keyboard]} slidesPerView={'auto'}>
                            {roadmap.list.map(slide => {
            return (<SwiperSlide key={slide.title}>
                                        <div className='roadmap_slider_slide'>
                                            <div className="blur"/>
                                            <h3>{slide.title}</h3>
                                            <h4>{slide.subtitle}</h4>
                                            <ul>
                                                {slide.points.map(el => {
                    return <li key={el.key} className='slide_info_text'>
                                                        <div className="content">
                                                            <span>{el.text}</span>
                                                            {el.status ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                                                    <path d="M17.4705 0.47784C17.7964 0.796631 17.9866 1.23629 17.9993 1.70015C18.012 2.16402 17.8461 2.6141 17.5382 2.95146L7.95299 13.4527C7.79764 13.6226 7.61096 13.7585 7.40397 13.8525C7.19699 13.9465 6.9739 13.9966 6.74789 13.9998C6.52187 14.0031 6.29752 13.9595 6.08808 13.8715C5.87864 13.7835 5.68837 13.653 5.5285 13.4877L0.453982 8.23707C0.155194 7.90529 -0.00746827 7.46646 0.00026353 7.01304C0.00799533 6.55962 0.185518 6.127 0.495431 5.80634C0.805344 5.48567 1.22345 5.30199 1.66167 5.29399C2.09988 5.28599 2.52399 5.45429 2.84464 5.76345L6.69 9.73991L15.0799 0.547848C15.388 0.210635 15.8129 0.0138295 16.2612 0.000701348C16.7095 -0.0124268 17.1445 0.159198 17.4705 0.47784Z" fill="white" fillOpacity="0.7"/>
                                                                </svg> : null}
                                                        </div>
                                                    </li>;
                })}
                                            </ul>
                                        </div>
                                    </SwiperSlide>);
        })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>);
};
export default RoadMap;

    async function __Next_Translate__getStaticProps__190dff087d3__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/RoadMap/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190dff087d3__ as getStaticProps }
  