
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const BigFeatures = ({ t }) => {
    const big_features = t('home:big_features', {}, { returnObjects: true });
    const ListItem = ({ item, i, dir }) => {
        return <li className='list_item' data-aos={`fade-${dir}`} data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 1)}>
            <img width={56} height={56} className={'list_item_img'} src={'/img/pages/home/big_feature/tax_icon.png'} alt={`$MPEPE ${item.title}`}/>
            <div className='list_item_content'>
                <h3 className='content_title'>{item.title}</h3>
                <p className='content_text'>{item.text}</p>
            </div>
        </li>;
    };
    return (<section className='big_features'>
            <div className="container">
                <div className="big_features_wrapper">
                    <h2 className='big_features_title'>{big_features.title}</h2>
                    <p className='big_features_subtitle'>{big_features.sub_title}</p>
                    <div className="big_features_list">
                        <ul className="list_left list_items">
                            {big_features.list_l.map((item, i) => {
            return <ListItem i={i} dir={'right'} key={item.key} item={item}/>;
        })}
                        </ul>
                        <div className="list_logo">
                            <img className='list_logo_image' src={'/logo.svg'} alt={'Buy $MPEPE'}/>
                        </div>
                        <ul className="list_right list_items">
                            {big_features.list_r.map((item, i) => {
            return <ListItem i={i} dir={'left'} key={item.key} item={item}/>;
        })}
                        </ul>
                    </div>
                </div>
            </div>
        </section>);
};
export default BigFeatures;

    async function __Next_Translate__getStaticProps__190dff087b7__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/BigFeatures/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190dff087b7__ as getStaticProps }
  