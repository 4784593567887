
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
const DoughnutChart = dynamic(() => import('./DoughnutChart'), { ssr: false });
const Tokenomics = ({ t, copyText }) => {
    const [mount, setMount] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const tokenomics = t('home:tokenomics', {}, { returnObjects: true });
    const DataItem = ({ item, i }) => {
        return <li className='data_items_item' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 1)}>
            <h3>{item.title}</h3>
            <p>{item.text}</p>
        </li>;
    };
    useEffect(() => {
        setMount(true);
    }, []);
    useEffect(() => {
        if (showToast) {
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        }
    }, [showToast]);
    return (<section className='tokenomics'>
            <div className={`toast ${showToast ? 'show' : ''}`}>
                <div className={'toast_inner'}>Address Copied</div>
            </div>
            <div className="container">
                <h2 className='tokenomics_title'>{tokenomics.title}</h2>
                <p className='tokenomics_sub_title'>{tokenomics.sub_title}</p>
                <div className='tokenomics_contract' id={'tokenomics_contract_cta'}>
                    <p className='tokenomics_contract_address'>0xd328a1C97e9b6b3Afd42eAf535bcB55A85cDcA7B</p>
                    <button className='tokenomics_contract_cta' onClick={() => copyText('0xd328a1C97e9b6b3Afd42eAf535bcB55A85cDcA7B', 'tokenomics_contract_cta', () => setShowToast(true))}>
                        {tokenomics.copy_cta}
                        <img src={'/img/pages/home/tokenomics/copy.svg'} alt={'copy $MPEPE address'}/>
                    </button>
                </div>
                <div className="tokenomics_chart_wrapper">
                    <div className='chart' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400}>
                        <div className="inner">
                            <img width={513} height={513} src={'/img/pages/home/tokenomics/chart_logo.png'} alt={'#MPEPE Tokenomics'}/>
                        </div>
                        <DoughnutChart height={350} width={400} innerRadius={55} radius={75} nameKey={"name"} valueKey={"y"} data={tokenomics.pie_data}/>
                    </div>
                    <ul className='data_items'>
                        {tokenomics.data.map((item, i) => {
            return <DataItem i={i} key={item.title} item={item}/>;
        })}
                    </ul>
                </div>
            </div>
        </section>);
};
export default Tokenomics;

    async function __Next_Translate__getStaticProps__190dff087c0__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Tokenomics/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190dff087c0__ as getStaticProps }
  