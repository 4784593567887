
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import MainButton from "../../../common/buttons/MainButton";
import Link from "next/link";
const Introducing = ({ t }) => {
    const introducing = t('home:introducing', {}, { returnObjects: true });
    return (<section className='introducing' id={'about'}>
            <div className="container">
                <div className="introducing_wrapper">
                    <div className="introducing_media">
                        <picture>
                            <source media="(max-width: 768px)" srcSet={`/img/pages/home/introducing/introducing.webp`} type="image/webp"/>
                            <source media="(min-width: 769px)" srcSet={`/img/pages/home/introducing/introducing_x2.webp`} type="image/webp"/>
                            <source media="(max-width: 768px)" srcSet={`/img/pages/home/introducing/introducing.png`}/>
                            <source media="(min-width: 769px)" srcSet={`/img/pages/home/introducing/introducing_x2.png`}/>
                            <img data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400} src={`/img/pages/home/introducing/introducing.png`} alt={'$MPEPE Rocket'} width={704} height={396} className='banner_rocket'/>
                        </picture>
                    </div>
                    <div className="introducing_content" data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400}>
                        <h2 className={'introducing_content_title'}>
                            <span>{introducing.title_1}</span>
                            <span>{introducing.title_2}</span>
                        </h2>
                        <p className={'introducing_content_description'}>{introducing.text_1}</p>
                        <p className={'introducing_content_description'}>{introducing.text_2}</p>
                        <div className={'introducing_content_buttons'}>
                            <Link className='button_buy' prefetch={false} href={process.env.NEXT_PUBLIC_APP_URL + '/sign-up'}>
                                <MainButton arrow text={introducing.cta_buy}/>
                            </Link>
                            <Link className='button_whitepaper' prefetch={false} target={'_blank'} href={process.env.NEXT_PUBLIC_WHITEPAPER_URL}>
                                <button>
                                    {introducing.cta_whitepaper}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>);
};
export default Introducing;

    async function __Next_Translate__getStaticProps__190dff08727__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Introducing/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190dff08727__ as getStaticProps }
  