
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const AsSeen = ({ t }) => {
    const featured = t('home:featured', {}, { returnObjects: true });
    const items = [
        { name: 'be_in', w: '122', h: '34' },
        { name: 'crypto_daily', w: '147', h: '40' },
        { name: 'u_today', w: '175', h: '26' },
        { name: 'benzinga', w: '196', h: '27' },
        { name: 'bitcoinist', w: '196', h: '39' }
    ];
    return (<section className='featured'>
            <div className='container'>
                <div className='featured_wrapper'>
                    <h2 className='featured_title'>{featured.title}</h2>
                    <div className='featured_items'>
                        {items.map((el, i) => {
            return <div key={el.name} className='featured_item' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 1)}>
                                <img src={`/img/pages/home/featured/${el.name}.svg`} alt={`$MPEPE featured in`} width={el.w} height={el.h} className=''/>
                            </div>;
        })}
                    </div>
                </div>
            </div>
        </section>);
};
export default AsSeen;

    async function __Next_Translate__getStaticProps__190dff0871f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Featured/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190dff0871f__ as getStaticProps }
  