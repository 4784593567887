
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const Team = ({ t }) => {
    const team = t('home:team', {}, { returnObjects: true });
    const TeamItem = ({ item, i }) => {
        return <li className='team_item' data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={100 * (i + 1)}>
            <picture>
                <source media="(max-width: 768px)" srcSet={`/img/pages/home/team/${item.img}.webp`} type="image/webp"/>
                <source media="(min-width: 769px)" srcSet={`/img/pages/home/team/${item.img}_x2.webp`} type="image/webp"/>
                <source media="(max-width: 768px)" srcSet={`/img/pages/home/team/${item.img}.png`}/>
                <source media="(min-width: 769px)" srcSet={`/img/pages/home/team/${item.img}_x2.png`}/>
                <img src={`/img/pages/home/team/${item.img}.png`} alt={'$MPEPE Team'} width={244} height={244} className='team_item_img'/>
            </picture>
            <h3 className='team_item_title'>{item.name}</h3>
            <p className='team_item_position'>{item.position}</p>
        </li>;
    };
    return (<section className={'team'}>
            <div className="container">
                <div className="team_wrapper">
                    <h2 className='team_title'>{team.title}</h2>
                    <ul className='team_items'>
                        {team.list.map((item, i) => {
            return <TeamItem i={i} key={item.key} item={item}/>;
        })}
                    </ul>
                </div>
            </div>
        </section>);
};
export default Team;

    async function __Next_Translate__getStaticProps__190dff087d9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/Team/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190dff087d9__ as getStaticProps }
  