
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
const BigPrize = ({ t }) => {
    const big_prize = t('home:big_prize', {}, { returnObjects: true });
    return (<section className={'prize'}>
            <div className="container">
                <div className="prize_wrapper">
                    <h2 className='prize_title'>{big_prize.title}</h2>
                    <p className='prize_sub_title'>{big_prize.sub_title}</p>
                    <picture>
                        <source media="(max-width: 768px)" srcSet={`/img/pages/home/big_prize/big_prize.webp`} type="image/webp"/>
                        <source media="(min-width: 769px)" srcSet={`/img/pages/home/big_prize/big_prize_x2.webp`} type="image/webp"/>
                        <source media="(max-width: 768px)" srcSet={`/img/pages/home/big_prize/big_prize.png`}/>
                        <source media="(min-width: 769px)" srcSet={`/img/pages/home/big_prize/big_prize_x2.png`}/>
                        <img data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad" data-aos-delay={400} src={`/img/pages/home/big_prize/big_prize.png`} alt={'$MPEPE Prize'} width={1143} height={570} className='prize_image'/>
                    </picture>
                </div>
            </div>
        </section>);
};
export default BigPrize;

    async function __Next_Translate__getStaticProps__190dff087af__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'components//home/BigPrize/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190dff087af__ as getStaticProps }
  